export const assetCategoriesOptions = [
	{
		label: 'Additional Tools',
		value: 'Additional Tools',
	},
	{
		label: 'Safety Equipment',
		value: 'Safety Equipment',
	},
	{
		label: 'Specialized PPE',
		value: 'Specialized PPE',
	},
	{
		label: 'PPE Standard',
		value: 'PPE Standard',
	},
]

export const assetTypeOptions = []
