import { CreateFile } from 'common'
import React from 'react'

interface FormProps {
	setFieldValue: (field: string, value: string) => void
	values: any
}

const SiteFiles = ({ setFieldValue, values }: FormProps) => {
	return (
		<div className="w-4/5 mb-8 px-2">
			<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
				Site Files
			</h2>
			<div className="grid grid-cols-2 grid-rows-2 gap-2 w-1/2">
				<div>
					<h4 className="text-md font-normal leading-5 px-4">File 1</h4>
					<CreateFile
						field="file_1"
						setFieldValue={setFieldValue}
						value={values.file_1}
					/>
				</div>
				<div>
					<h4 className="text-md font-normal leading-5 px-4">File 2</h4>
					<CreateFile
						field="file_2"
						setFieldValue={setFieldValue}
						value={values.file_2}
					/>
				</div>
				<div>
					<h4 className="text-md font-normal leading-5 px-4">File 3</h4>
					<CreateFile
						field="file_3"
						setFieldValue={setFieldValue}
						value={values.file_3}
					/>
				</div>
				<div>
					<h4 className="text-md font-normal leading-5 px-4">File 4</h4>
					<CreateFile
						field="file_4"
						setFieldValue={setFieldValue}
						value={values.file_4}
					/>
				</div>
			</div>
		</div>
	)
}

export default SiteFiles
