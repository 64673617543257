import { FC, useState } from 'react'

interface SeeMoreLessProps {
	text: string
	maxLength?: number
}

export const SeeMoreLess: FC<SeeMoreLessProps> = ({ text, maxLength = 75 }) => {
	const [isExpanded, setIsExpanded] = useState(false)

	const toggleExpand = () => {
		setIsExpanded(!isExpanded)
	}

	const isTextLong = text.length > maxLength

	return (
		<div>
			<p>
				{isExpanded || !isTextLong ? text : `${text.slice(0, maxLength)}`}
				{isTextLong && !isExpanded && '...'}
			</p>
			{isTextLong && (
				<button onClick={toggleExpand} className=" text-blue-600">
					{isExpanded ? 'See Less' : 'See More'}
				</button>
			)}
		</div>
	)
}
