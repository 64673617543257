import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

type staffNameType = {
	staffData: {
		staff_name: string
	}
}

export const useTimesheets = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllTimesheets = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverTimesheetRoutes.getAllTimesheets
			)

			const sortedResponse = response?.data?.sort(
				(a: staffNameType, b: staffNameType) => {
					const nameA = a.staffData ? a.staffData.staff_name.toUpperCase() : ''
					const nameB = b.staffData ? b.staffData.staff_name.toUpperCase() : ''
					return nameA.localeCompare(nameB)
				}
			)

			return sortedResponse
		} catch (error) {
			showError('Something went wrong getting Timesheets')
			throw new Error('Something went wrong getting Timesheets')
		}
	}

	const { data, isLoading, error } = useQuery(
		['timesheets'],
		fetchAllTimesheets
	)

	return { data, isLoading, error }
}
