export const active_inactive_options = [
	{
		value: 'Active',
		label: 'Active',
	},
	{
		value: 'Inactive',

		label: 'Inactive',
	},
]

export const yes_no_options = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
]
