import { CheckIcon } from '@heroicons/react/24/solid'
import { Button, ConfirmationDialog } from 'common'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { TimesheetServices } from 'services'

interface ApproveTimesheet {
	timesheets: {
		id: number
		status: string
	}[]
}
export const ApproveTimesheet = ({ timesheets }: ApproveTimesheet) => {
	const { updateTimesheet } = TimesheetServices.useUpdateTimesheetById()
	const userState = useSelector((store: AppStore) => store.user)
	const handleApproveTimesheets = async () => {
		const selectedTimesheets = timesheets.map(
			(row: { id: number; status: string }) => ({
				id: row.id,
				status: 'Approved',
				approved_by: userState.name,
			})
		)

		await updateTimesheet(selectedTimesheets)
	}

	return (
		<ConfirmationDialog
			icon="info"
			title={'Approve Timesheet'}
			body="Are you sure you wish to approve this timesheet?"
			triggerButton={
				<Button
					variant="approve"
					size="sm"
					startIcon={
						<CheckIcon
							className="-ml-0.5 mr-2 h-4 w-4 text-white"
							aria-hidden="true"
						/>
					}>
					Approve Timesheets
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					variant="approve"
					onClick={async () => handleApproveTimesheets()}>
					Approve
				</Button>
			}
		/>
	)
}
