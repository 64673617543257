import React, { Fragment, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { FormActions } from 'common/Form'
interface SideModalProps {
	heading: string
	open: boolean
	setOpen: (open: boolean) => void
	children: ReactNode
	handleSubmit: () => void
	isLoading: boolean
	formType: 'create' | 'update'
}

export function SideModal({
	heading,
	open,
	setOpen,
	children,
	handleSubmit,
	isLoading,
	formType,
}: SideModalProps) {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed inset-0 overflow-hidden"
				open={open}
				onClose={setOpen}>
				<div className="absolute inset-0 overflow-hidden">
					<Dialog.Overlay className="absolute inset-0" />
					<div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
						<Transition.Child
							as={Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-x-full"
							enterTo="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-x-0"
							leaveTo="translate-x-full">
							<div className="w-screen max-w-2xl z-50">
								<form
									className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll z-50"
									onSubmit={handleSubmit}>
									<div className="flex-1">
										{/* Header */}
										<div className="px-4 py-6 bg-gray-50 sm:px-6">
											<div className="flex items-start justify-between space-x-3">
												<div className="space-y-1">
													<Dialog.Title className="text-lg font-medium text-gray-900">
														{heading}
													</Dialog.Title>
												</div>
												<div className="h-7 flex items-center">
													<button
														type="button"
														className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
														onClick={() => setOpen(false)}>
														<span className="sr-only">Close panel</span>
														<XCircleIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>

										{/* Divider container */}
										<div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
											{children}
										</div>
									</div>
									<FormActions
										setOpen={setOpen}
										isLoading={isLoading}
										formType={formType}
									/>
								</form>
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
