import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useSupportTicketsByClient = (client?: string | number | null) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchSupportsTicketByClient = async () => {
		try {
			if (!client) return null
			const response = await getRequest(
				AppRoutes.serverSupportTicketRoutes.getAllSupportTicketsByClient,
				undefined,
				{ client }
			)
			if (response.code === 200) {
				return response.data
			} else {
				const data = {
					client,
					data: {
						code: 500,
						data: [],
						message: 'Support Ticket list',
					},
				}
				return data
			}
		} catch (error) {
			showError('Something went wrong getting Support Tickets')
			throw new Error('Something went wrong getting Support Tickets')
		}
	}

	const { data, isLoading, error } = useQuery(
		[`${client}-supportTickets`, client],
		fetchSupportsTicketByClient
	)

	return { data, isLoading, error }
}
