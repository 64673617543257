import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'

export const Spinner = () => {

	// Random animation time from 500ms to 1500ms, a string formatted as 0.5s 1.1s 
	const [animationTime] = React.useState(`${(Math.random() * 1000 + 500) / 1000}s`)

	return (
		<div className="w-full h-full flex justify-center items-center">
			<ProgressSpinner animationDuration={animationTime}/>
		</div>
	)
}
