import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateSupportTicket = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const updateSupportTicket = async (id: number, supportTicket: any) => {
		try {
			const response = await postRequest(
				AppRoutes.serverSupportTicketRoutes.updateSupportTicket,
				supportTicket,
				id
			)
			queryClient.invalidateQueries(['supportTickets'])
			showSuccess('Support Ticket updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating the Support Ticket')
			throw new Error((error as Error).message)
		}
	}
	return { updateSupportTicket }
}
