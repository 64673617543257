import { Dialog as UIDialog, Transition } from '@headlessui/react'
import * as React from 'react'
import 'intersection-observer'

export const DialogTitle = UIDialog.Title

export const DialogDescription = UIDialog.Description

interface DialogProps {
	isOpen: boolean
	onClose: () => void
	children: React.ReactNode
	initialFocus?: React.RefObject<HTMLElement>
}

export const Dialog = ({
	isOpen,
	onClose,
	children,
	initialFocus,
}: DialogProps) => (
	<>
		<Transition.Root show={isOpen} as={React.Fragment}>
			<UIDialog
				as="div"
				static
				className="fixed z-10 inset-0 overflow-y-auto"
				open={isOpen}
				onClose={onClose}
				initialFocus={initialFocus}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={React.Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<UIDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={React.Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						{children}
					</Transition.Child>
				</div>
			</UIDialog>
		</Transition.Root>
	</>
)
