import { useEffect, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { DataTableServices, SupportServices } from 'services'
import {
	Container,
	DataTableHeader,
	PageHeading,
	Badge,
	SeeMoreLess,
	Dropdown,
} from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
// import { SupportComponents } from 'components'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'
import { PencilIcon } from '@heroicons/react/24/solid'
import moment from 'moment'

export const clientOptions = [
	{ value: 'allways', label: 'Allways' },
	{ value: 'crockett', label: 'Crockett' },
	{ value: 'elite', label: 'Elite' },
	{ value: 'erectnz', label: 'Erectnz' },
	{ value: 'fivestar', label: 'Fivestar' },
	{ value: 'grovescaff', label: 'Grovescaff' },
	{ value: 'myscaffold', label: 'My Scaffold' },
	{ value: 'nayland', label: 'Nayland' },
	{ value: 'nbscaffold', label: 'NB Scaffold' },
	{ value: 'nssv2', label: 'NSS V2' },
	{ value: 'oldfields', label: 'Oldfields' },
	{ value: 'protrade', label: 'Protrade' },
	{ value: 'tana', label: 'Tana' },
	{ value: 'toowoomba', label: 'Toowoomba' },
]

export const SupportMainTable = () => {
	const location = useLocation()
	// const { data, isLoading, enableCreateUpdate } =
	// 	SupportServices.useSupportTickets()
	const [openTicketForm, setOpenTicketForm] = useState(false)
	const [selectedClient, setSelectedClient] = useState('')
	const [supportTickets, setSupportTickets] = useState([])

	const {
		data: ticketsData,
		isLoading: ticketsDataLoading,
		error,
	} = SupportServices.useSupportTicketsByClient(selectedClient)

	useEffect(() => {
		if (!ticketsDataLoading) {
			if (ticketsData) {
				setSupportTickets(ticketsData.data.data)
			} else {
				setSupportTickets([])
			}
		}
	}, [ticketsDataLoading, ticketsData])

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		FilterColumn,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'type',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Urget Bug Fix',
					'Non-Urgent Bug Fix',
					'Change Request',
					'Variation',
					'Query',
				],
			},
			{
				filterName: 'status',
				filterInitialValue: 'Closed',
				filterInitialMatchMode: FilterMatchMode.NOT_EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Open',
					'In Progress',
					'More Info Required',
					'Client Signoff',
					'Closed',
				],
			},
		],
		aditionalGlobalFilterFields: [
			'ticketId',
			'title',
			'description',
			'notes',
			'uploadedByData.staff_name',
			'dueDate',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageHeading
				title="Support"
				// createBtn={enableCreateUpdate ? 'Create Ticket' : undefined}
				// isEditable={false}
				// setOpen={setOpenTicketForm}
			/>
			<div className="px-8 w-2/12 -mt-6 pb-6">
				<Dropdown
					label="Select Client"
					id="clientDropdown"
					options={clientOptions}
					value={selectedClient || ''}
					onChange={(id: string, val: string) => {
						setSelectedClient(val)
					}}
					isLoading={ticketsDataLoading}
					error={error ? 'Error fetching tickets' : ''}
				/>
			</div>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={supportTickets}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={ticketsDataLoading}
					dataKey="id"
					sortField="id"
					sortOrder={-1}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					stripedRows
					emptyMessage="No support tickets found.">
					<Column
						field="ticketId"
						header="Ticket ID"
						style={{ width: '7rem' }}
						sortable
					/>
					<Column field="type" header="Type" {...FilterColumn.type} />
					<Column field="title" header="Title" style={{ width: '20rem' }} />
					<Column
						field="description"
						header="Description"
						style={{
							maxWidth: '20rem',
							textAlign: 'left',
							wordBreak: 'break-word',
						}}
						body={(rowData) => {
							return <SeeMoreLess text={rowData.description} />
						}}
					/>
					<Column
						field="notes"
						header="ScaffM8 Notes"
						style={{ maxWidth: '20rem', textAlign: 'left' }}
						body={(rowData) => {
							return <SeeMoreLess text={rowData.notes} />
						}}
					/>
					<Column
						field="file1"
						header="File 1"
						body={(rowData) => {
							if (rowData.file1) {
								return (
									<a
										href={rowData.file1}
										target="_blank"
										rel="noreferrer"
										className="text-blue-600">
										File Link
									</a>
								)
							}
							return <>No File Link</>
						}}
					/>
					<Column
						field="file2"
						header="File 2"
						body={(rowData) => {
							if (rowData.file2) {
								return (
									<a
										href={rowData.file2}
										target="_blank"
										rel="noreferrer"
										className="text-blue-600">
										File Link
									</a>
								)
							}
							return <>No File Link</>
						}}
					/>
					<Column
						field="link"
						header="Link"
						body={(rowData) => {
							if (rowData.link) {
								return (
									<a
										href={rowData.link}
										target="_blank"
										rel="noreferrer"
										className="text-blue-600">
										Link
									</a>
								)
							}
							return <>No Link</>
						}}
					/>
					<Column
						field="status"
						header="Status"
						body={(row: { status: string }) => {
							let color
							switch (row.status) {
								case 'Open':
									color = 'Red'
									break
								case 'More Info Required':
									color = 'Blue'
									break
								case 'Client Signoff':
									color = 'Yellow'
									break
								case 'Closed':
									color = 'Green'
									break
								case 'In Progress':
									color = 'Orange'
									break
								default:
									color = 'Gray'
							}
							return <Badge type={color} text={String(row.status)} />
						}}
						{...FilterColumn.status}
					/>
					<Column field="uploadedByData.staff_name" header="Uploaded By" />
					<Column
						field="createdAt"
						header="Created At"
						body={(rowData) => (
							<>{moment(rowData.createdAt).format('DD/MM/YYYY')}</>
						)}
					/>
					<Column field="dueDate" header="Due Date" />
					<Column field="dateCompleted" header="Date Completed" />
					<Column field="hours" header="Hours" />
					{/* {enableCreateUpdate && (
						<Column
							header="Edit"
							body={(rowData) => (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.SupportTicketEdit.replace(
											':id',
											rowData.id
										),
									}}
									state={{ background: location, name: 'editSupportTicket' }}>
									<PencilIcon className="text-gray-600 h-4 w-4" />
								</Link>
							)}
						/>
					)} */}
				</DataTable>
			</Container>
			{/* <SupportComponents.SupportForm
				formType="create"
				heading="Create Ticket"
				open={openTicketForm}
				setOpen={setOpenTicketForm}
			/> */}
		</div>
	)
}
