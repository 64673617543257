import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// import { SupportComponents } from 'components'

export const SupportTicketEdit = () => {
	const [openTicketForm, setOpenTicketForm] = useState(true)
	const navigate = useNavigate()

	const { id } = useParams()

	useEffect(() => {
		if (!openTicketForm) {
			navigate(-1)
		}
	}, [openTicketForm])

	return (
		// <SupportComponents.SupportForm
		// 	formType="update"
		// 	heading="Edit Ticket"
		// 	open={openTicketForm}
		// 	setOpen={setOpenTicketForm}
		// 	support_id={Number(id)}
		// />
		<></>
	)
}
