import { Formik } from 'formik'
import { SideModal, Dropdown, Spinner, DateSelect, TextArea } from 'common'

import { LeaveServices, StaffServices } from 'services'
import { OptionsForDropdown } from 'utilities'

interface EditLeaveFormProps {
	heading: string
	open: boolean
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	leaveId: number
}

export function EditLeaveForm({
	heading,
	open,
	setOpen,
	formType = 'create',
	leaveId,
}: EditLeaveFormProps) {
	const updateLeaveMutation = LeaveServices.useUpdateLeave()
	const staffQuery = StaffServices.useStaff()
	const { data, isLoading } = LeaveServices.useLeaveById(leaveId)

	const leaveOptions = [
		{ value: 'Annual', label: 'Annual' },
		{ value: 'Sick', label: 'Sick' },
		{ value: 'Bereavement', label: 'Bereavement' },
		{ value: 'Leave Without Pay', label: 'Leave Without Pay' },
		{ value: 'Other', label: 'Other' },
	]

	if (isLoading)
		return (
			<div className="w-full h-48 flex justify-center items-center">
				<Spinner />
			</div>
		)

	return (
		<div>
			<Formik
				initialValues={{
					start_date: data.startDate || null,
					end_date: data.endDate || null,
					staff_id: data?.staffId || null,
					type: data.type || '',
					comments: data.comments || '',
				}}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					setSubmitting(true)

					await updateLeaveMutation.updateLeave(leaveId, {
						approvedBy: data.approvedBy,
						comments: values.comments,
						endDate: values.end_date,
						staffId: values.staff_id,
						startDate: values.start_date,
						status: data.status,
						totalDays: data.totalDays,
						type: values.type,
					})
					setSubmitting(false)
					setOpen(false)
					resetForm()
				}}>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					setFieldTouched,
				}) => (
					<SideModal
						heading={heading}
						open={open}
						setOpen={setOpen}
						handleSubmit={handleSubmit}
						isLoading={isSubmitting}
						formType={formType}>
						<div className="flex items-center">
							<Dropdown
								label="Staff"
								id="staff_id"
								value={values.staff_id}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								options={OptionsForDropdown(
									staffQuery.data,
									'id',
									'staff_name'
								)}
							/>
							<Dropdown
								label="Leave Type"
								id="type"
								options={leaveOptions}
								value={values.type}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
							/>
						</div>
						<div className="flex items-center">
							<DateSelect
								title="Start Date"
								id="start_date"
								value={values.start_date}
								onChange={setFieldValue}
							/>
							<DateSelect
								title="Finish Date"
								id="end_date"
								value={values.end_date}
								onChange={setFieldValue}
							/>
						</div>
						<div>
							<TextArea
								title="Comments"
								id="comments"
								type="text"
								handleChange={handleChange}
								handleBlur={handleBlur}
								value={values.comments}
							/>
						</div>
					</SideModal>
				)}
			</Formik>
		</div>
	)
}
