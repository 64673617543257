import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LeaveComponents } from 'components'

export const LeaveEdit = () => {
	const [openLeaveForm, setOpenLeaveForm] = useState(true)
	const navigate = useNavigate()

	const { id } = useParams()

	useEffect(() => {
		if (!openLeaveForm) {
			navigate(-1)
		}
	}, [openLeaveForm])

	return (
		<LeaveComponents.EditLeaveForm
			formType="update"
			heading="Edit Leave"
			open={openLeaveForm}
			setOpen={setOpenLeaveForm}
			leaveId={Number(id)}
		/>
	)
}
