export const investigationReportsTypeOptions = [
	'Site Inspection Safety Checklist',
	'Incident',
	'Accident / Incident',
	'Scaffold Inspection',
	'Accident Investigation',
	'Prestart: Equipment & Additional Check',
	'HR Incident: Record of Discussion',
	'HR Incident: Disciplinary Procedure or Removal from Site',
	'HR Incident: Positive',
	'Vehicle Inspections',
	'Forklift Inspection',
	'Harness Inspections',
	'Lanyard Inspections',
	'Hoist Inspection',
	'Sling / Strop / Ratchet / Chain Inspection',
	'Office / Yard Inspection',
	'Pre-Start: New Hazard / Tool Box',
]
